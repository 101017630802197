import { errorToast, successToast, infoToast, warningToast } from "../utilities/MoshaToast";

const formMixin = {
    data() {
        return {
            session: {
                id_company: 1,
                id_branch: 1,
                user: null,
                token: null,
                theme: 'white'
            }
        }
    },
    created() {
        this.session.id_company = 1; 
        this.session.id_branch = 1; 
        this.session.user = this.$storage.getStorageSync("user"); 
        this.session.token = this.$storage.getStorageSync("token"); 
    },
    methods: {
        showError(error, options) {
            errorToast(error, options);
        },
        showMessage(message, options) {
            infoToast(message, options);
        },
        showSuccess(message, options) {
            successToast(message, options);
        },
        showWarning(message, options) {
            warningToast(message, options);
        }
    },
}

export default formMixin;